.thought {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
}

.thought-text {
  text-align: center;
  font-size: 7rem;
  font-weight: 300;
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.1rem;
  text-decoration: underline;
}

@media screen and (max-width: 1280px) {
  .thought {
    padding: 1rem 3rem 7rem;
  }
}
