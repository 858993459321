.reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 7rem 0;
}

.reviews-text img {
  width: 100%;
  margin: 3rem 0;
}

.reviews-wrap {
  width: 100%;
  margin-top: 3rem;
  overflow: hidden;
}

.reviews-wrap::-webkit-scrollbar {
  display: none;
}

.reviews-carousel {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  padding: 0 3rem;
  overflow-x: auto;
}

.reviews-carousel::-webkit-scrollbar {
  display: none;
}

.reviews-carousel-item {
  height: 35rem;
  border: 0;
  border-radius: 1rem;
}

.reviews-carousel-item video {
  height: 100%;
  border-radius: 1rem;
}
