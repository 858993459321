.banner {
  position: relative;
  width: 100%;
  height: 100vh;
}

.banner-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.banner-wrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
}

.banner-title {
  width: 42rem;
  font-size: 3.25rem;
  font-weight: 550;
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.1rem;
}

.banner-text {
  width: 52rem;
  margin: 3rem 0 4rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  line-height: 1.7;
}

.banner-button {
  display: block;
  width: 18rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 550;
  color: #fff;
  background-color: #1050fe;
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  .banner {
    width: 100%;
    height: auto;
  }
  .banner-bg {
    position: static;
    width: 100%;
    height: 100rem;
  }
  .banner-wrap {
    justify-content: flex-end;
  }
  .banner-button {
    display: none;
  }
  .banner-title {
    font-size: 5rem;
  }
  .banner-text {
    font-size: 2rem;
  }
}

@media screen and (max-width: 760px) {
  .banner-title {
    width: 80rem;
    font-size: 8rem;
  }
  .banner-text {
    width: 80rem;
    font-size: 3rem;
  }
}
