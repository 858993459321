.page-title-wrap {
  padding: 3rem 0;
}

.page-title-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font: inherit;
  text-decoration: none;
}

.page-title-link img {
  width: 1.5rem;
}

.page-title-link p {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}

.page-title {
  text-align: center;
  font-size: 7rem;
  font-weight: 300;
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 1280px) {
  .page-title-wrap {
    padding: 8rem 3rem;
  }
  .page-title-link {
    margin-bottom: 3rem;
  }
  .page-title-link img {
    width: 3rem;
  }
  .page-title-link p {
    color: #fff;
    font-size: 2rem;
  }
}

@media screen and (max-width: 760px) {
  .page-title-link img {
    width: 5rem;
  }
  .page-title-link p {
    color: #fff;
    font-size: 3rem;
  }
  .page-title {
    font-size: 9rem;
  }
}
