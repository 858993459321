.footer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 7rem 0;
  background-color: #1050fe;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-contacts-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font: inherit;
  color: #fff;
  text-decoration: none;
}

.footer-contacts-link img {
  height: 1.8rem;
}

.footer-contacts-link p {
  font-size: 1.5rem;
}

.footer-docs-link {
  margin-bottom: 1rem;
  font: inherit;
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  .footer-contacts-link {
    gap: 3rem;
    margin-bottom: 3rem;
  }
  .footer-contacts-link img {
    height: 4rem;
  }
  .footer-contacts-link p {
    font-size: 3rem;
  }
}

@media screen and (max-width: 760px) {
  .footer-contacts-link {
    gap: 3rem;
    margin-bottom: 3rem;
  }
  .footer-contacts-link img {
    height: 6rem;
  }
  .footer-contacts-link p {
    font-size: 5rem;
  }
}
