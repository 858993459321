.club-sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.club-sections-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin: 3rem 0;
}

.club-sections-photo {
  height: 45rem;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.club-sections-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4rem 5rem;
}

.club-sections-title {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.1rem;
}

.club-sections-text {
  max-width: 70%;
  margin: 3rem 0 7rem;
  font-size: 1.2rem;
  font-weight: 300;
  color: #fff;
  line-height: 1.7;
}

.club-sections-button {
  display: block;
  width: 18rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 550;
  color: #fff;
  background-color: #1050fe;
  text-decoration: none;
}

@media screen and (max-width: 1280px) {
  .club-sections-grid {
    grid-template-columns: 1fr;
    padding: 0 3rem;
  }
  .club-sections-photo {
    height: 70rem;
  }
  .club-sections-title {
    font-size: 4rem;
  }
  .club-sections-text {
    max-width: 80rem;
    font-size: 2rem;
  }
  .club-sections-button {
    width: 30rem;
    padding: 3rem;
    font-size: 2rem;
  }
}

@media screen and (max-width: 760px) {
  .club-sections-grid {
    grid-template-columns: 1fr;
    padding: 0 3rem;
  }
  .club-sections-title {
    font-size: 5rem;
  }
  .club-sections-text {
    font-size: 3rem;
  }
  .club-sections-button {
    width: 100%;
    padding: 4rem;
    margin-bottom: 3rem;
    font-size: 3rem;
  }
}
