.title {
  font-size: 3rem;
  font-weight: 550;
  text-align: center;
  letter-spacing: .1rem;
  color: #fff;
}

@media screen and (max-width: 1280px) {
  .title {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 760px) {
  .title {
    font-size: 6rem;
  }
}
