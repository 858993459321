.club-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -7rem;
  margin-bottom: 3rem;
}

.club-info-img {
  width: 30rem;
  height: 40rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.club-info-text {
  align-self: flex-end;
  margin-top: 2rem;
  width: 50%;
  font-size: 1.2rem;
  font-weight: 300;
  color: #fff;
  line-height: 1.5;
}

@media screen and (max-width: 1280px) {
  .club-info {
    margin-top: -12rem;
  }
  .club-info-img {
    width: 80rem;
    height: 110rem;
  }
  .club-info-text {
    align-self: center;
    text-align: center;
    margin-top: 2rem;
    width: 80%;
    font-size: 2rem;
  }
}

@media screen and (max-width: 760px) {
  .club-info {
    margin-top: -13rem;
  }
  .club-info-text {
    width: 90%;
    font-size: 3rem;
  }
}
