.text {
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  line-height: 1.7;
}

@media screen and (max-width: 1280px) {
  .text {
    font-size: 2rem;
  }
}

@media screen and (max-width: 760px) {
  .text {
    font-size: 3rem;
  }
}