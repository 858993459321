.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7rem 0;
}

.about-photo-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
}

.about-photo {
  height: 25rem;
  background-position: 0 5%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1280px) {
  .about {
    padding: 7rem 3rem;
  }
}

@media screen and (max-width: 760px) {
  .about-photo-wrap {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .about-photo {
    height: 72rem;
  }
}
