@import url(./assets/fonts/Montserrat/stylesheet.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-family: "Montserrat";
  font-size: clamp(10px, 0.83333vw, 20px);
}

body {
  width: 100%;
  background-color: #202020;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content {
  width: 100rem;
}

.text-right {
  text-align: right;
}

.info-right {
  align-items: flex-end;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 1vw;
  }
  .content {
    width: 100%;
    padding: 0 3rem;
  }
  .text-right {
    text-align: left;
  }
  .order-3 {
    order: 4;
  }
  .order-4 {
    order: 3;
  }
  .info-right {
    align-items: flex-start;
  }
}
